<template>
  <b-container
    class="d-flex flex-column align-items-center justify-content-center mx-0 px-0 root-layout"
    fluid
  >
    <div>
      <b-img
        src="@/assets/Figa_reglog.png"
        class="Figalogo-img"
        alt="Figalogo"
        fill
      ></b-img>
    </div>

    <b-card
      body-class="text-center"
      style="max-width: 70rem; border-radius: 14.4px"
      class="mt-4"
    >
      <div class="login-view">
        <b-img
          src="@/assets/Administrator-icon.png"
          class="logo-img"
          alt="Finance"
        ></b-img>

        <b-row
          class="d-flex flex-row align-items-center justify-content-center"
          no-gutters
        >
          <b-col>
            <h3
              class="text-center text-main-green font-primary font-weight-bold"
            >
              Administrator Login
            </h3>

            <h5
              class="text-center text-main-green font-primary font-weight-normal"
            >
              Please enter your admin credentials to gain access
            </h5>
          </b-col>
        </b-row>

        <form id="login-form">
          <b-row
            class="d-flex flex-row align-items-center justify-content-center"
            no-gutters
          >
            <b-col>
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="rounded-2"
                label="Email Account"
                :state="emailError.status"
                :invalidFeedback="`${emailError.message}`"
                v-model="email"
                placeholder="user@example.com"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
                style="border-color: #8793a3"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-center"
            no-gutters
          >
            <b-col>
              <FormInputGroup
                groupId="password-group"
                id="password-group-input"
                class="input-field"
                v-model="password"
                label="Password"
                :state="passwordError.status"
                :invalidFeedback="`${passwordError.message}`"
                placeholder="Minimum 6 characters"
                isRequired
                :whiteBG="false"
                :type="showPassword ? 'text' : 'password'"
                form="login-form"
                isAppend
              >
                <template v-slot:append>
                  <b-button
                    class="px-3 px-md-4 rounded-2"
                    variant="transparent"
                    @click="showPassword = !showPassword"
                  >
                    <b-icon
                      :icon="showPassword ? 'eye-slash' : 'eye'"
                      aria-label="Help"
                    ></b-icon>
                  </b-button>
                </template>
              </FormInputGroup>
            </b-col>
          </b-row>
          <!-- error status -->
          <b-row
            v-if="loginError.status"
            class="d-flex flex-row align-items-center justify-content-center w-100 mb-3"
            no-gutters
          >
            <b-col cols="12" md="10">
              <span class="text-danger text-small text-center">{{
                this.loginError.message
              }}</span>
            </b-col>
          </b-row>
          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isBlock
                isPill
                variant="main-green"
                class="font-weight-normal text-secondary mt-2 login-button"
                type="submit"
                :isLoading="isLoader"
                :class="{ 'btn-disabled': !isFormValid }"
                :disabled="!isFormValid"
                @click.native="LoginFn"
                ><span class="text-white">Login</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <b-link
                to="/forgot-password"
                class="font-primary font-weight-normal text-main-green"
                >Forgot Password ?</b-link
              >
            </b-col>
          </b-row>
        </form>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";

import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";

import axios from "axios";

export default {
  name: "Login",
  components: {
    FormInput,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      isLoader: false,
      email: "",
      password: "",
      showPassword: false,
      emailError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      loginError: {
        status: false,
        message: "",
      },
      registerUri: "",
    };
  },
  mounted() {
    const orginalUri = "/register";
    const next = this.$route.query.next;
    if (next) {
      this.registerUri = `${orginalUri}?next=${next}`;
    } else {
      this.registerUri = orginalUri;
    }
  },
  watch: {
    email(value) {
      this.loginError.status = false;
      if (value) {
        if (CheckEmail(value) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
    password(value) {
      this.loginError.status = false;
      if (value) {
        if (value.length < 6) {
          this.passwordError.status = false;
          this.passwordError.message =
            "A minimum length of 6 characters needed";
        } else {
          this.passwordError.status = true;
        }
      } else {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
  },
  computed: {
    isFormValid() {
      return this.email !== "" && this.password !== "";
    },
  },
  methods: {
    ...mapActions({
      localLogIn: "auth/localLogIn",
    }),

    async LoginFn() {
      try {
        const payload = {
          email: this.email,
          password: this.password,
        };
        const response = await axios.post("/admin/login", payload);
        if (response.status == 200) {
          if (response.data.msg === "Account has been disabled") {
            this.$router.push("/");
            this.$bvToast.toast("Account has been deactivated.", {
              title: "Account",
              toaster: "b-toaster-bottom-right",
              variant: "danger",
              solid: true,
            });
            return;
          }
          else{
          console.log(response);
          localStorage.setItem("figa_name", response.data.name);
          localStorage.setItem("figa_access_token", response.data.access_token);
          localStorage.setItem(
            "figa_refresh_token",
            response.data.refreshToken
          );

          this.$router.push("/dashboard");
          this.$bvToast.toast("Login Successfull", {
            title: "Login Successfull",
            toaster: "b-toaster-bottom-right",
            variant: "success",
            solid: true,
          });
        }
        } else {
          this.$bvToast.toast("Username or password is wrong", {
            title: "Login Failed",
            toaster: "b-toaster-bottom-right",
            variant: "danger",
            solid: true,
          });
        }
        return response;
      } catch (error) {
        console.log(error.response);
        this.$bvToast.toast("Username or password is wrong", {
          title: "Login Failed",
          toaster: "b-toaster-bottom-right",
          variant: "danger",

          solid: true,
        });
        throw error;
      }
    },
    validateForm() {
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }

      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }

      if (this.password < 6) {
        this.passwordError.status = false;
        this.passwordError.message = "A minimum length of 6 characters needed";
      }
    },
  },
};
</script>

<style scoped>
.root-layout {
  position: relative;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;

  background-color: #ffe400;
}

.logo-img {
  align-items: center;
  width: 54px;
  height: 54px;
}
.root-layout .Figalogo-img {
  align-items: center;
  width: 180px;
  height: 60px;
}
.login-view {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 36px;
  padding-bottom: 50px;
  min-width: 30vw;
}
h5 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  padding-bottom: 25.6px;

  /* Grey 2 */

  color: #828282;
}
h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  padding-top: 14px;
  /* Color/Green */

  color: #0b9b7d;
}

/* .login-view .login-button{
width: 162px;
height: 50px;
background: #0B9B7D;
border-radius: 30px;

} */

.login-view .login-button {
  border-radius: 30px;
  width: 162px;
  height: 50px;
  background: #0b9b7d;
}
</style>
